<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    添加商品详情活动
                </div>
            </template>
    <el-form>
        <el-form-item label="活动标题:">
            <el-input style="width:200px" v-model="title" />
        </el-form-item>
        <el-form-item label="活动图片:">
            <span v-if="imgurl">原始图片：<el-image style="width: 100px;height:100px" :src="imgurl" ></el-image></span>
            <el-upload
                :action="uploadfile"
                list-type="picture-card"
                accept="image/*"
                limit=1
                :on-preview="onPreview"
                :on-remove="onRemove"
                :on-success="handleAvatarSuccess"
                class="upload"
            >
            <img class="jiahao" :src="jiahao"  alt="">
            </el-upload>
            <el-dialog v-model="lookpic">
                <el-image style="width: 100%;" :src="imageUrl"  fit="contain" />
            </el-dialog>
        </el-form-item>
        <el-form-item label="活动位置:">
            <el-radio-group v-model="activity" class="ml-4">
                <el-radio label="1" size="large">平台活动</el-radio>
                <el-radio label="2" size="large">商品活动</el-radio>
                <el-radio label="3" size="large">服务保障</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="选择跳转:">
            <el-select v-model="jump" clearable @change="changeselect" placeholder="不跳转">
                <el-option label="页面跳转" value="1" />
                <el-option label="商品" value="2" />
                <el-option label="商品集合页" value="3" />
                <el-option label="活动页链接" value="4" />
            </el-select>
            <el-tag v-if="jump == 1 && jumpwhere" style="margin-left:10px">已选择
                《<span v-if="radio == 1">首页</span>
                <span v-if="radio == 2">分页类</span>
                <span v-if="radio == 3">商品列表</span>
                <span v-if="radio == 4">我的</span>》
            </el-tag>
            <el-form-item v-if="jump == 2 && part">
                <el-tag  style="margin-right:10px">{{itemss.PdtName}}</el-tag>
            </el-form-item>
            <el-button type="success" v-if="jump == 3" @click="goodsmanages" style="margin-left:10px">商品管理</el-button>
            <el-form-item v-if="jump == 3 && goodsmanage">
                <el-tag  v-for="i in gms" :key="i.ID" @close="tagclose(i)" closable style="margin-right:10px">{{i.PdtName}}</el-tag>
            </el-form-item>
            <el-form-item v-if="jump == 4" style="margin-top:15px">
                <el-input v-model="lianjie" placeholder="请填写连接地址" style="width:500px" />
            </el-form-item>
        </el-form-item>
        <el-form-item label="商品范围:">
            <el-radio-group v-model="goodsrang" @change="change" class="ml-4">
                <el-radio label="1" size="large">指定商品</el-radio>
                <el-radio label="2" size="large">指定品类</el-radio>
                <el-radio label="3" size="large">指定品类+品牌</el-radio>
                <el-radio label="4" size="large">指定品类+品牌+型号</el-radio>
                <el-radio label="5" size="large">所有商品</el-radio>
            </el-radio-group><br/>
            <el-form-item v-if="rang == 1">
                <el-button @click="partgoodsbtn" size="small">请选择商品</el-button>
                <el-form-item v-if="partgoods">
                    <el-tag
                        v-for="(item,i) in partgoodss"
                        :key="item.ID"
                        closable
                        style="margin-right:10px"
                        @close="handleClose(i)"
                    >
                        {{ item.PdtName }}
                    </el-tag>
                </el-form-item>
            </el-form-item>
            <el-form-item v-if="rang == 2 || rang == 3 || rang == 4">
                <el-cascader :options="option" 
                        v-model="rangcategoryid" 
                        placeholder="选择品类" 
                        clearable
                        style="margin-right:10px;" 
                        :show-all-levels="false"
                        :props="props"
                        @change="rangSetBrand"
                        size="small"
                        ></el-cascader>
                <el-select v-model="rangbrandid" v-if="rang == 3 || rang == 4"  size="small" placeholder="选择品牌" filterable style="margin-right:10px;width:150px" clearable :disabled="rangcategoryid?false:true" @change="rangSetModel" value-key="brand.ID">
                    <el-option v-for="brand in brands"
                        :key="brand.ID"
                        :label="brand.Brand"
                        :value="brand.ID"></el-option>
                </el-select>
                <el-select v-model="rangmodelid" v-if="rang == 4" placeholder="选择型号" filterable style="margin-right:10px;width:150px" clearable :disabled="rangbrandid?false:true" :loading="loading" @visible-change="rangGetModel" size="small" value-key="model.ID">
                    <el-option v-for="model in models"
                        :key="model.ID"
                        :label="model.Model"
                        :value="model.ID"></el-option>
                </el-select>
            </el-form-item>
        </el-form-item>
        <!-- <el-form-item label="适用商户:">
            <el-radio-group v-model="business" @change="radioselect" class="ml-4">
                <el-radio label="1" size="large">全部商户</el-radio>
                <el-radio label="2" size="large">部分商户</el-radio>
                <el-button v-if="business == 2" type="primary" @click="selectmerchant" size="small">选择商户</el-button>
            </el-radio-group>
            <el-form-item v-if="merchant">
                <el-tag style="margin-right:10px"  v-for="list in selectlist" :key="list.ID" >{{list.Merchant}}</el-tag>
            </el-form-item>
        </el-form-item> -->
        <el-form-item label="活动状态:">
            <el-radio-group v-model="status" class="ml-4">
                <el-radio label="1" size="large">启用</el-radio>
                <el-radio label="2" size="large">停用</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-button @click="goback">返回</el-button>
        </el-form-item>
    </el-form>
    </el-card>
    </div>
    <!--页面跳转-->
    <el-dialog
        v-model="pagejump"
        title="选择目标页面"
        width="30%"
    >
    <el-row >
        <el-col :span="12" style="min-height: 36px;line-height:36px;">页面标题</el-col>
        <el-col :span="12" style="min-height: 36px;line-height:36px;">页面地址</el-col>
    </el-row>
    <el-row style="border-top:1px solid #ebeef5">
        <el-col :span="12" style="min-height: 36px;line-height:36px;"><el-radio v-model="radio" label="1">首页</el-radio></el-col>
        <el-col :span="12" style="min-height: 36px;line-height:36px;">/pages/index/index</el-col>
    </el-row>
    <el-row style="border-top: 1px solid #ebeef5;">
        <el-col :span="12" style="min-height: 36px;line-height:36px;"><el-radio v-model="radio" label="2">分页类</el-radio></el-col>
        <el-col :span="12" style="min-height: 36px;line-height:36px;"> /pages/category/category</el-col>
    </el-row>
    <el-row style="border-top: 1px solid #ebeef5;">
        <el-col :span="12" style="min-height: 36px;line-height:36px;"><el-radio v-model="radio" label="3">商品列表</el-radio></el-col>
        <el-col :span="12" style="min-height: 36px;line-height:36px;">/pages/goodslist/goodslist</el-col>
    </el-row>
    <el-row style="border-top: 1px solid #ebeef5;border-bottom: 1px solid #ebeef5;">
        <el-col :span="12" style="min-height: 36px;line-height:36px;"><el-radio v-model="radio" label="4">我的</el-radio></el-col>
        <el-col :span="12" style="min-height: 36px;line-height:36px;">/pages/my/my</el-col>
    </el-row>
        <template #footer>
            <span class="dialog-footer">
            <el-button @click="pagejump = false">取消</el-button>
            <el-button type="primary" @click="pagejumpclick">确定</el-button>
            </span>
        </template>
    </el-dialog>
    <!--页面跳转-->
    <!--商品跳转-->
    <el-dialog
        v-model="goods"
        title="选择商品"
        width="55%"
    >
        <el-cascader :options="option" 
                    v-model="categoryid" 
                    placeholder="选择品类" 
                    clearable
                    style="margin-right:10px;" 
                    :show-all-levels="false"
                    :props="props"
                    @change="SetBrand"
                    size="small"
                    ></el-cascader>
        <el-select v-model="brandid" size="small" placeholder="选择品牌" filterable style="margin-right:10px;width:150px" clearable :disabled="categoryid?false:true" @change="SetModel" value-key="brand.ID">
            <el-option v-for="brand in brands"
                :key="brand.ID"
                :label="brand.Brand"
                :value="brand.ID"></el-option>
        </el-select>
        
        <el-select v-model="modelid" placeholder="选择型号" filterable style="margin-right:10px;width:150px" clearable :disabled="brandid?false:true" :loading="loading" @visible-change="GetModel" size="small" value-key="model.ID">
            <el-option v-for="model in models"
                :key="model.ID"
                :label="model.Model"
                :value="model.ID"></el-option>
        </el-select>
        <el-input v-model="keyword" placeholder="商品标题/商品编码" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
        <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
        <el-scrollbar height="400px">
            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                size="small">
                    <el-table-column label="商品主图" >
                        <template #default='scope'>
                            <el-radio v-model="radioID" @change="changradio" style="display:flex;align-items:center" :label="scope.$index">
                            <el-image style="width: 60px; height: 60px" :src="scope.row.PdtThumb" fit="fit" /></el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户名称:商品名称" prop="Merchant">
                        <template #default="scope">
                            <span v-if="scope.row.Merchant">{{scope.row.Merchant}}:</span>
                            <span v-else>深圳市闲牛科技有限公司:</span>
                            <span>{{scope.row.PdtName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品地址">
                        <template #default="scope">
                            <span>/pages/goods/goods?id={{scope.row.ID}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
        </el-scrollbar>
    <template #footer>
    <span class="dialog-footer">
        <el-button @click="goods = false">取消</el-button>
        <el-button type="primary" @click="goodssbtn"
        >确定</el-button
        >
    </span>
    </template>
    </el-dialog>
    <!--商品跳转-->
    <!--选择商户-->
    <el-dialog
        v-model="selectmer"
        title="选择商户"
        width="25%"
    >
    <el-scrollbar height="400px">
        <el-table
            ref="multipleTableRef"
            :data="merchantlist"
            style="width: 100%"
            @selection-change="selectionchange"
        >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="Merchant" label="商户名称" />
        </el-table>
    </el-scrollbar>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="selectmer = false">取消</el-button>
            <el-button type="primary" @click="closemerchant"
            >确定</el-button
            >
        </span>
        </template>
    </el-dialog>
    <!--选择商户-->
    <!--部分商品-->
    <el-dialog
        v-model="partgood"
        title="选择商品"
        width="55%"
    >
    <el-cascader :options="option" 
                    v-model="categoryid" 
                    placeholder="选择品类" 
                    clearable
                    style="margin-right:10px;" 
                    :show-all-levels="false"
                    :props="props"
                    @change="SetBrand"
                    size="small"
                    ></el-cascader>
        <el-select v-model="brandid" size="small" placeholder="选择品牌" filterable style="margin-right:10px;width:150px" clearable :disabled="categoryid?false:true" @change="SetModel" value-key="brand.ID">
            <el-option v-for="brand in brands"
                :key="brand.ID"
                :label="brand.Brand"
                :value="brand.ID"></el-option>
        </el-select>
        
        <el-select v-model="modelid" placeholder="选择型号" filterable style="margin-right:10px;width:150px" clearable :disabled="brandid?false:true" :loading="loading" @visible-change="GetModel" size="small" value-key="model.ID">
            <el-option v-for="model in models"
                :key="model.ID"
                :label="model.Model"
                :value="model.ID"></el-option>
        </el-select>
        <el-input v-model="keyword" placeholder="商品标题/商品编码" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
        <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
        <el-scrollbar height="400px">
            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                @select="CheckSelectionpart"
                                size="small">
                    <el-table-column type="selection" width="40"></el-table-column>
                    <el-table-column label="商品主图" >
                        <template #default='scope'>
                            <el-image style="width: 60px; height: 60px" :src="scope.row.PdtThumb" fit="fit"  />
                        </template>
                    </el-table-column>
                    <el-table-column label="商户名称:商品名称" prop="Merchant">
                        <template #default="scope">
                            <span v-if="scope.row.Merchant">{{scope.row.Merchant}}:</span>
                            <span v-else>深圳市闲牛科技有限公司:</span>
                            <span>{{scope.row.PdtName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品地址">
                        <template #default="scope">
                            <span>/pages/goods/goods?id={{scope.row.ID}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
        </el-scrollbar>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="partgood = false">取消</el-button>
            <el-button type="primary" @click="confimpartgoods"
            >确定</el-button
            >
        </span>
        </template>
    </el-dialog>
    <!--部分商品-->
    <!--商品管理集合页-->
    <el-dialog
        v-model="gm"
        title="选择商品"
        width="55%"
    >
    <el-cascader :options="option" 
                    v-model="categoryid" 
                    placeholder="选择品类" 
                    clearable
                    style="margin-right:10px;" 
                    :show-all-levels="false"
                    :props="props"
                    @change="SetBrand"
                    size="small"
                    ></el-cascader>
        <el-select v-model="brandid" size="small" placeholder="选择品牌" filterable style="margin-right:10px;width:150px" clearable :disabled="categoryid?false:true" @change="SetModel" value-key="brand.ID">
            <el-option v-for="brand in brands"
                :key="brand.ID"
                :label="brand.Brand"
                :value="brand.ID"></el-option>
        </el-select>
        
        <el-select v-model="modelid" placeholder="选择型号" filterable style="margin-right:10px;width:150px" clearable :disabled="brandid?false:true" :loading="loading" @visible-change="GetModel" size="small" value-key="model.ID">
            <el-option v-for="model in models"
                :key="model.ID"
                :label="model.Model"
                :value="model.ID"></el-option>
        </el-select>
        <el-input v-model="keyword" placeholder="商品标题/商品编码" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
        <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
        <el-scrollbar height="400px">
            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                @select="CheckSelectiongm"
                                @select-all = "checkSelectAll"
                                @selection-change = "checkSelectChange"
                                size="small">
                    <el-table-column type="selection" width="40"></el-table-column>
                    <el-table-column label="商品主图" >
                        <template #default='scope'>
                            <el-image style="width: 60px; height: 60px" :src="scope.row.PdtThumb" fit="fit"  />
                        </template>
                    </el-table-column>
                    <el-table-column label="商户名称:商品名称" prop="Merchant">
                        <template #default="scope">
                            <span v-if="scope.row.Merchant">{{scope.row.Merchant}}:</span>
                            <span v-else>深圳市闲牛科技有限公司:</span>
                            <span>{{scope.row.PdtName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品地址">
                        <template #default="scope">
                            <span>/pages/goods/goods?id={{scope.row.ID}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
        </el-scrollbar>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="gm = false">取消</el-button>
            <el-button type="primary" @click="confimpartgms"
            >确定</el-button
            >
        </span>
        </template>
    </el-dialog>
    <!--商品管理-->
</template>

<script>
// import { Plus } from '@element-plus/icons-vue'
import constant from "@/constant"
import qs from "qs"
// import type { UploadFile } from '@element-plus'
import { ElMessage } from 'element-plus'
import { ref } from 'vue';
export default{
    data(){
        return{
            rangid:ref(''),
            imgurl:ref(''),
            gm:ref(false),
            gms:[],
            radioID:ref(),
            goodsrang:ref(''),
            goodsmanage:ref(false),
            lookpic:ref(false),
            uploadfile:constant.uploadfile,
            part:ref(false),
            jumpwhere:ref(false),
            goodss:[],
            partgood:ref(false),
            partgoods:ref(false),
            partgoodss:[],
            merchant:ref(false),
            mainpic:ref(),
            tabledata:[],
            keyword:"",
            props:{
                label:'label',
                value:'value',
                disabled:'Disabled'
            },
            loading:false,
            load:false,
            curpage:1,
            pagesize:10,
            totalcount:0,
            option:[],
            categoryid:"",
            brandid:'',
            modelid:'',
            rangcategoryid:"",
            rangbrandid:'',
            rangmodelid:'',
            title:ref(""),
            imageUrl : ref(''),
            activity:ref(""),
            jump:ref(''),//跳转
            scope:ref(),//商品范围
            business:ref("1"),//选择商户
            status:ref("1"),
            lianjie:ref(),
            rang:ref(),
            jiahao:require('@/assets/image/jiahao.png'),
            pagejump:ref(false),
            goods:ref(false),
            radio:ref(''),
            selectmer:ref(false),
            merchantlist:[],
            selectlist:[],
            itemss:[],
        }
    },
    components:{
        // Plus
    },
    created:function(){
        var keyword = this.$route.query.keyword;
        if(keyword)
            this.keyword = keyword;
        var categoryid = this.$route.query.categoryid;
        if(categoryid)
            this.categoryid = JSON.parse(categoryid);
        var brandid = this.$route.query.brandid;
        
        var modelid = this.$route.query.modelid;
        let id = this.$route.query.id
        if(id){
            this.rangid = id
            this.axios.get(constant.getedit,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data)
                this.title = response.data.title
                if(response.data.imageUrl != ''){
                    this.imgurl = response.data.imageUrl
                    this.imageUrl = response.data.imageUrl
                }
                this.activity = response.data.activity
                this.jump = response.data.jump
                if(response.data.jump == 4){
                    this.lianjie = response.data.jumpcontent
                }
                if(response.data.jump == 1){
                    this.jumpwhere = true
                    this.radio = response.data.radio
                }
                if(response.data.jump == 3){
                    this.axios.get(constant.get_pdt_url,{
                        headers:{
                            'Content-Type': 'application/json'
                        },
                        params:{
                            aid:response.data.jumpcontent
                        }
                    }).then((response)=>{
                        this.goodsmanage = true
                        this.gms = response.data.list
                        console.log(response.data)
                    })
                }
                this.goodsrang  = response.data.goodsrang
                if(response.data.goodsrang == 1){
                    this.rang = 1
                    if(response.data.goodscontent.length > 0){
                        this.axios.get(constant.get_pdt_url,{
                            headers:{
                                'Content-Type': 'application/json'
                            },
                            params:{
                                aid:response.data.goodscontent
                            }
                        }).then((response)=>{
                            this.partgoods = true
                            this.partgoodss = response.data.list
                            console.log(response.data)
                        })
                    }
                }
                if(response.data.goodsrang == 2){
                    this.rang = 2
                    this.rangcategoryid = response.data.goodscontent
                }
                if(response.data.goodsrang == 3){
                    this.rang = 3
                    let gd1 = response.data.goodscontent.split(",")
                    console.log(gd1)
                    this.rangcategoryid = gd1[0]
                    // this.brandid = gd1[1]
                    this.rangbrandid = gd1[1]
                }
                if(response.data.goodsrang == 4){
                    this.rang = 4
                    // let gd2 = response.data.goodscontent.split(",")
                    // this.rangcategoryid = gd2[0]
                    // this.rangbrandid = gd2[1]
                    // this.rangmodelid = gd2[2]
                    
                }
                this.business = response.data.business
                this.status = response.data.status
                // if(response.data.jump == 1){
                //     this.radio = response.data.jumpcontent
                // }
                // if(response.data.jump == 2){
                //     console.log(response.data.jump)
                // }
                // if(response.data.jump == 4){
                //     this.lianjie = response.data.jumpcontent
                // }
                
            })
        }else{
            this.rangid = ''
        }
        //商品品类
        this.axios.get(constant.get_cascader_url,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.option = response.data;
        });
        this.axios.get(constant.get_brand_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                status: 1
            }
        }).then((response)=>{
            console.log(response.data);
            
            this.brands = response.data;
            if(this.brands.length > 0){
                if(brandid)
                    this.brandid = brandid;
            }
        });
        this.axios.get(constant.get_model_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                status: 1
            }
        }).then((response)=>{
            console.log(response.data);
            this.models = response.data;
            if(this.models.length > 0)
                if(modelid)
                    this.modelid = modelid;
        });
        // this.init()
    },
    methods:{
        handleClose(i){
            console.log(this.partgoodss)
            this.partgoodss.splice(i,1)
        },
        onSubmit(){
            if(this.title == ''){
                ElMessage({
                    type:'warning',
                    message:"请填写活动标题"
                })
                return false
            }
            if(this.imageUrl == ''){
                ElMessage({
                    type:'warning',
                    message:"请上传活动图片"
                })
                return false
            }
            if(this.activity == ''){
                ElMessage({
                    type:"warning",
                    message:"请选择活动位置"
                })
                return false
            }
            if(this.jump == ''){
                ElMessage({
                    type:"warning",
                    message:"请选择跳转"
                })
                return false
            }else{
                if(this.jump == 1){
                    if(this.radio == ''){
                        ElMessage({
                            type:"warning",
                            message:"页面跳转未指定页面"
                        })
                        return false
                    }else{
                        if(this.radio == 1){
                            this.radio = '/pages/index/index'
                        }
                        if(this.radio == 2){
                            this.radio = '/pages/category/category'
                        }
                        if(this.radio == 3){
                            this.radio = '/pages/goodslist/goodslist'
                        }
                        if(this.radio == 4){
                            this.radio = '/pages/my/my'
                        }
                    }
                }
                if(this.jump == 2){
                    if(this.itemss.length == 0){
                        ElMessage({
                            type:"warning",
                            message:"选择跳转商品中未指定商品"
                        })
                        return false
                    }
                }
                if(this.jump == 3){
                    if(this.gms.length == 0){
                        ElMessage({
                            type:"warning",
                            message:"选择跳转商品集合页未选择商品"
                        })
                        return false
                    }
                }
                if(this.jump == 4){
                    if(this.lianjie == ''){
                        ElMessage({
                            type:"warning",
                            message:"请填写活动页链接地址"
                        })
                        return false
                    }
                }
            }
            if(this.goodsrang == ''){
                ElMessage({
                    type:"warning",
                    message:"请选择商品范围"
                })
                return false
            }else{
                if(this.goodsrang == 1){
                    if(this.partgoodss.length == 0){
                        ElMessage({
                            type:"warning",
                            message:"商品范围指定的商品未选择商品"
                        })
                        return false
                    }
                }
                if(this.goodsrang == 2){
                    if(this.rangcategoryid == ''){
                        ElMessage({
                            type:"warning",
                            message:"商品范围指定的品类未选择品类"
                        })
                        return false
                    }
                }
                if(this.goodsrang == 3){
                    if(this.rangbrandid == ''){
                        ElMessage({
                            type:"warning",
                            message:"商品范围指定的品类+品牌未选择品牌"
                        })
                        return false
                    }
                }
                if(this.goodsrang == 4){
                    if(this.rangmodelid == ''){
                        ElMessage({
                            type:"warning",
                            message:"商品范围指定的品类+品牌+型号未选择型号"
                        })
                        return false
                    }
                }
            }
            if(this.business == 2){
                if(this.selectlist.length == 0){
                    ElMessage({
                        type:"warning",
                        message:"请选择商户"
                    })
                    return false
                }
            }
            console.log(this.partgoodss)
            var pd = "";
            for(var ii=0;ii<this.partgoodss.length;ii++){
                console.log(this.partgoodss[ii].ID)
                pd+=this.partgoodss[ii].ID+",";
            }
            console.log(pd)
            var params = {
                    id:this.rangid,
                    title:this.title,//活动标题
                    imageUrl:this.imageUrl,//活动图片
                    activity:this.activity,//活动位置
                    jump:this.jump,//选择跳转
                    radio:this.radio,//目标页面
                    itemss:this.itemss,//单个商品
                    gms:this.gms,//多个商品
                    lianjie:this.lianjie,//活动页链接
                    goodsrang:this.goodsrang,//商品范围
                    partgoodss:pd,//部分多个商品
                    rangcategoryid:this.rangcategoryid,//品类
                    rangbrandid:this.rangbrandid,//品牌
                    rangmodelid:this.rangmodelid,//型号
                    business:this.business,//适用商户
                    selectlist:this.selectlist,//部分商户
                    statusactivity: this.status//活动状态
                }
                console.log(params)
            let that = this
            this.axios.post(constant.addgoodsdetail,qs.stringify(params),{
                headers:{
                    "content-type":"application/x-www-form-urlencoded"
                }
            }).then((response)=>{
                console.log(response)
                let typenew = ""
                if(response.data.code == 400){
                    typenew += 'error'
                }else{
                    typenew += 'success'
                }
                ElMessage({
                    type:typenew,
                    message:response.data.msg
                })
                setTimeout(function(){
                    that.$router.go(-1)
                },2000)
            })
        },
        tagclose(id){
            this.gms.splice(id,1)
        },
        goodsmanages(){
            this.gm = true
            this.init()
        },
        changradio(index){
            this.itemss = this.tabledata[index]
        },
        goodssbtn(){
            // this.itemss = this.goodss
            this.part = true
            this.goods = false
        },
        pagejumpclick(){
            console.log(this.radio)
            if(this.radio === ''){
                ElMessage({
                    type:"warning",
                    message:"请选择要跳转的页面"
                })
            }else{
                this.jumpwhere = true
                this.pagejump = false
            }
        },
        //选择部分商品
        CheckSelection(val, row){
            this.part = false
            let id = row.ID
            var l = this.itemss.length
            if(l > 0){
                for(var i=0;i<=l-1;i++){
                    if(this.itemss[i].ID == id){
                        this.itemss.splice(i,1)
                        return false
                    }
                }
                this.itemss.push(row)
            }else{
                this.itemss.push(row)
            }
        },
        //部分商品
        confimpartgoods(){
            this.partgood = false
            this.partgoods = true
        },
        confimpartgms(){
            this.gm = false
            this.goodsmanage = true
        },
        CheckSelectionpart(val,row){
            // this.partgoods = false
            let id = row.ID
            var l = this.partgoodss.length
            if(l > 0){
                for(var i=0;i<=l-1;i++){
                    if(this.partgoodss[i].ID == id){
                        // this.partgoodss.splice(i,1)
                        return false
                    }
                }
                this.partgoodss.push(row)
            }else{
                this.partgoodss.push(row)
            }
        },
        checkSelectChange(selection){
            console.log(selection)
        },
        checkSelectAll(selection){
            console.log(selection)
            // this.gms.push(selection)
            if(selection.length > 0){
                // for(){

                // }
            }
            //console.log(this.gms)
        },
        CheckSelectiongm(val,row){
            console.log(val)
            let id = row.ID
            var l = this.gms.length
            if(l > 0){
                for(var i=0;i<=l-1;i++){
                    if(this.gms[i].ID == id){
                        // this.gms.splice(i,1)
                        return false
                    }
                }
                this.gms.push(row)
            }else{
                this.gms.push(row)
            }
            console.log(this.gms)
        },
        partgoodsbtn(){
            this.partgood = true
            this.init()
        },
        radioselect(val){
            console.log(val)
            if(val == 1){
                this.selectlist = []
            }
        },
        closemerchant(){
            if(this.selectlist.length == 0){
                ElMessage({
                    type:"success",
                    message:"还未选择商户"
                })
            }else{
                this.selectmer = false
                this.merchant = true
            }
        },
        selectionchange(val){
            console.log(val)
            this.selectlist = val
            console.log(this.selectlist)
        },
        selectmerchant(){
            this.selectmer = true
            this.merchant = false
            this.axios.get(constant.allmerchant,{
                headers:{
                    "Content-Type":'application/json'
                }
            }).then((response)=>{
                console.log(response.data);
                this.merchantlist = response.data;
                // for(let i = 0;i<list.length;i++){
                //     let obj = new Object();
                //     obj.label = list[i]['Merchant'];
                //     obj.key = list[i]['ID'];
                //     this.merchantlist.push(obj);
                // }
                console.log(this.merchantlist);
            });
        },
        init(){
            this.load = true;
            this.axios.get(constant.get_pdt_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    keyword:this.keyword,
                    categoryid:JSON.stringify(this.categoryid),
                    brandid:this.brandid,
                    modelid:this.modelid,
                    status:2,
                    curpage:this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.totalcount = response.data.totalcount*1;
                this.curpage = response.data.curpage*1;
                this.load = false;
            });
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        SetModel(){
            this.modelid = "";
        },
        rangSetModel(){
            this.rangmodeid = ""
        },
        GetModel(){
            this.loading = true;
            this.axios.get(constant.get_plan_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    category:JSON.stringify(this.categoryid),
                    brand:this.brandid,
                    status:1,
                }
            }).then((response)=>{
                console.log(response.data);
                this.models = response.data;
                this.loading = false;
            });
        },
        rangGetModel(){
            this.loading = true;
            this.axios.get(constant.get_plan_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    category:JSON.stringify(this.rangcategoryid),
                    brand:this.rangbrandid,
                    status:1,
                }
            }).then((response)=>{
                console.log(response.data);
                this.models = response.data;
                this.loading = false;
            });
        },
        SetBrand(val){
            console.log(val)
            this.brandid = "";
            this.modelid = "";
        },
        rangSetBrand(val){
            console.log(val)
            this.rangbrandid = ""
            this.rangmodelid = ""
        },
        changeselect(value){
            console.log(value)
            if(value == 1){
                this.pagejump = true
                this.jumpwhere = false
                this.radio = ""
            }
            if(value == 2){
                this.goods = true
                this.itemss = []
                this.part = false
                this.init()
            }
            if(value == 3){
                this.gms = []
            }
            if(value == 4){
                this.lianjie = ""
            }
        },
        goback(){
            this.$router.go(-1);
        },
        change(value){
            this.rang = value
        },
        add(){
            this.$router.push({path:"/addproductdetailactivity"})
        },
        onPreview(file){
            console.log(file.response.url)
            this.lookpic = true
            // this.imageUrl = file.response.url
        },  
        onRemove(file){
            console.log(file)
            this.imageUrl = ""
        },
        handleAvatarSuccess(data){
            console.log(data.url)
            this.imageUrl = data.url
        }
        
    }
}
</script>

<style>
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-upload--picture-card{
    position: relative;
}
.el-upload--picture-card .jiahao{
    position: relative;
    top:18px;
}
.box-item {
    width: 92px;
    padding:0;
}
</style>